import React from "react";

import CHeader from "../components/CHeader";
import CFooter from "../components/CFooter";
import { Link } from "react-router-dom";

export default class P404 extends React.Component {

    render() {
        return (
            <>
                <CHeader />
                <main className="flex-auto">
                    <section className="container-fluid py-3">
                        <div className="row">
                            <div className="col-12 text-center">
                                <h2 className="display-1">404</h2>
                                <h2 className="display-5">Page introuvable</h2>
                            </div>
                            <div className="col-12 text-center">
                                <p className="mt-3">
                                    Le lien saisie ou sur lequel vous avez cliqué est inacessible.
                                    <br />
                                    <Link to="/">Cliquez ici </Link> pour retourner à la <Link to="/">page d'accueil</Link>.
                                </p>
                            </div>
                        </div>
                    </section>
                </main>
                <CFooter />
            </>
        );
    }
}