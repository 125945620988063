import {
    createBrowserRouter,
} from "react-router-dom";
import PAccueil from "../pages/PAccueil";
import PContact from "../pages/PContact";
import P404 from "../pages/P404";
import POffre from "../pages/POffre";
import PServices from "../pages/PServices";



const router = createBrowserRouter([
  {
    path: "/",
    element: <PAccueil />,
  },
  {
    path: "/services",
    element: <PServices />,
  },
  {
    path: "/contact",
    element: <PContact />,
  },
  {
    path: "/abonnement",
    element: <POffre />,
  },
  {
    path: "*",
    element: <P404 />,
  },
]);

export default router;