import React from "react";

import CHeader from "../components/CHeader";
import CFooter from "../components/CFooter";

export default class PContact extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            person: {
                fisrtName: "",
                name: "",
                email: "",
                phone: "",
                address: "",
                city: "",
                postalCode: "",
                company: "",
                message: ""
            },
            isProcessing: false,
            toast: {
                show: false,
                title: "Notification",
                message: "",
                type: ""
            }
        }
    }

    handleFormContact(e) {
        e.preventDefault();
        const fd = new FormData(e.target);
        this.setState({isProcessing: true});
        fetch("https://api.glimm.ca/api/contact", {
            method: "POST",
            body: fd
        })
        .then(response => response.json())
        .then(response => {
            if (response.error) throw response;
            this.handleToastNotify(response.message, "Notification", "success");
        })
        .catch(error => {
            this.handleToastNotify("L'opération à échouée!\n" + error.message, "Erreur !", "error");
        })
        .finally(() => {
            this.setState({isProcessing: false});
        });
    }

    handleChange(e) {
        const person = this.state.person;
        person[e.target.name] = e.target.value;
        this.setState({
            person: person
        });
    }

    handleToastNotify(message, title="Notification", type="info") {
        this.setState({
            toast: {
                show: true,
                title: title,
                message: message,
                type: type
            }
        });
    }

    handleCloseToast() {
        const toast = this.state.toast;
        toast.show = false;
        this.setState(toast);
    }

    render() {
        return (
            <>
                <CHeader />
                <main className="flex-auto">
                    <section className="container-fluid py-3" id="contact">
                        <div className="row">
                            <div className="col-12">
                                <h2 className="border-bottom pb-3">Nous contacter</h2>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-5 my-3">
                                <p>Vous pouvez nous contacter de quatre façons.</p>
                                <div className="d-flex align-item-center">
                                    <div className="contact-icon me-3">
                                        <i className="fa-solid fa-2xl fa-headset text-muted"></i>
                                    </div>
                                    <div className="contact-description">
                                        <p className="my-3">
                                            En nous appellant au 
                                            <br />
                                            <a href="tel:+18196216295"><strong>+1 (819) 362-4502</strong></a>
                                        </p>
                                    </div>
                                </div>
                                <p className="border-bottom col-2 text-muted pb-2">OU</p>
                                <div className="d-flex align-item-center">
                                    <div className="contact-icon me-3">
                                        <i className="fa-solid fa-2xl fa-envelope text-muted"></i>
                                    </div>
                                    <div className="contact-description">
                                        <p className="my-3">
                                            En nous laissant un courriel électronique à l'adresse
                                            <br />
                                            <a href="mailto:contact@glimmigration.ca"><strong>contact@glimm.ca</strong></a>
                                        </p>
                                    </div>
                                </div>
                                <p className="border-bottom col-4 text-muted pb-2">OU</p>
                                <div className="d-flex align-item-center">
                                    <div className="contact-icon me-3">
                                        <i className="fa-solid fa-2xl fa-location-dot text-muted"></i>
                                    </div>
                                    <div className="contact-description">
                                        <p className="my-3">
                                            En nous laissant un courriel à l'adresse
                                            <br />
                                            <strong>1857 rue St Calixte, Plessisvile, QC</strong>
                                            <br />
                                            <strong>G6L 1R7</strong>
                                        </p>
                                    </div>
                                </div>
                                <p className="border-bottom col-6 text-muted pb-2">OU ENCORE</p>
                                <p>
                                    En remplissant le formulaire de contact suivant et bien décrire dans le message le motif du contact. 
                                    Nous reviendrons vers vous au plus vite !
                                </p>
                            </div>
                            <div className="col-md-7">
                                <form id="form-contact" className="form row mb-3" onSubmit={(e) => this.handleFormContact(e)}>
                                    <div className="form-group col-6 mt-3">
                                        <label htmlFor="personName">Prénom <sup>*</sup> : </label>
                                        <input type="text" className="form-control rounded-0" name="name" id="personName" placeholder="Votre Prénom" onInput={(e) => this.handleChange(e)} required />
                                    </div>
                                    <div className="form-group col-6 mt-3">
                                        <label htmlFor="personFirstName">Nom <sup>*</sup> : </label>
                                        <input type="text" className="form-control rounded-0" name="firstName" id="personFirstName" placeholder="Votre Nom" onInput={(e) => this.handleChange(e)} required />
                                    </div>
                                    <div className="form-group col-6 mt-3">
                                        <label htmlFor="personEmail">Email <sup>*</sup> : </label>
                                        <input type="email" className="form-control rounded-0" name="email" id="personEmail" placeholder="Email" onInput={(e) => this.handleChange(e)} required />
                                    </div>
                                    <div className="form-group col-6 mt-3">
                                        <label htmlFor="personPhone">Téléphone <sup>*</sup> : </label>
                                        <input type="number" className="form-control rounded-0" name="phone" id="personPhone" placeholder="Numéro de téléphone" onInput={(e) => this.handleChange(e)} required />
                                    </div>
                                    <div className="form-group col-6 mt-3">
                                        <label htmlFor="personAddress">Adresse <sup>*</sup> : </label>
                                        <input type="text" className="form-control rounded-0" name="address" id="personAddress" placeholder="Adresse" onInput={(e) => this.handleChange(e)} required />
                                    </div>
                                    <div className="form-group col-6 mt-3">
                                        <label htmlFor="personCity">Ville <sup>*</sup> : </label>
                                        <input type="text" className="form-control rounded-0" name="city" id="personCity" placeholder="Ville" onInput={(e) => this.handleChange(e)} />
                                    </div>
                                    <div className="form-group col-6 mt-3">
                                        <label htmlFor="personPostalCode">Code postal <sup>*</sup> : </label>
                                        <input type="text" className="form-control rounded-0" name="postalCode" id="personPostalCode" placeholder="Code postal" onInput={(e) => this.handleChange(e)} required />
                                    </div>
                                    <div className="form-group col-6 mt-3">
                                        <label htmlFor="company">Entreprise : </label>
                                        <input type="text" className="form-control rounded-0" name="company" id="company" placeholder="Nom de votre entreprise (optionel)" onInput={(e) => this.handleChange(e)} />
                                    </div>
                                    <div className="form-group col-12 mt-3">
                                        <label htmlFor="message">Message <sup>*</sup> : </label>
                                        <textarea type="text" className="form-control rounded-0" name="message" id="message" placeholder="Entrez une bonne description de votre besoin ou de votre requête." rows="8" onInput={(e) => this.handleChange(e)} required></textarea>
                                    </div>
                                    <div className="form-group col-12 mt-3">
                                        <label htmlFor="file">Joindre un fichier (si nécessaire) : </label>
                                        <input type="file" className="form-control rounded-0" name="file[]" id="file" multiple />
                                    </div>
                                    <div className="form-group col-12 mt-4">
                                        <button className="btn btn-success btn-lg rounded-0" type="submit" disabled={this.state.isProcessing}>
                                            {this.state.isProcessing && <i className="fa-solid fa-spinner me-2"></i>}
                                            Envoyer mon message
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </section>
                    { this.state.toast.show &&
                    <div className="toast-container position-fixed bottom-0 end-0 p-3">
                        <div className={"toast show alert-" + this.state.toast.type} role="alert" aria-live="assertive" aria-atomic="true">
                            <div className="toast-header">
                                <strong className="me-auto">{ this.state.toast.title }</strong>
                                <small>...</small>
                                <button type="button" className="btn-close" onClick={() => this.handleCloseToast()}></button>
                            </div>
                            <div className="toast-body">
                                { this.state.toast.message }
                            </div>
                        </div>
                    </div>
                    }
                </main>
                <CFooter />
            </>
        );
    }
}