import React from "react";

import CHeader from "../components/CHeader";
import CFooter from "../components/CFooter";
import { Link } from "react-router-dom";

export default class POffre extends React.Component {

    services = []

    constructor(props) {
        super(props);
        this.state = {
            person: {
                fisrtName: "",
                name: "",
                company: "",
                city: "",
                address: "",
                postalCode: "",
                phone: "",
                email: "",
                service: "",
                type: 0,
                carModel: "",
                numberOfRoom: "",
                area: "",
                date: "",
                message: ""
            },
            isProcessing: false,
            toast: {
                show: false,
                title: "Notification",
                message: "",
                type: ""
            },
            services: []
        }
    }

    componentDidMount() {
        const queryParams = new URLSearchParams(window.location.search);
        const service = queryParams.get("service");
        const type = queryParams.get("type");
        if (service) {
            const person = this.state.person;
            person.service = service;
            if (type) person.type = type;
            this.setState({person: person}, () => {
                this.handleServiceChange(null);
            });
        }
    }

    handleFormOffer(e) {
        e.preventDefault();
        const fd = new FormData(e.target);
        this.setState({isProcessing: true});
        fetch("https://api.glimm.ca/api/entretien/abonnement", {
            method: "POST",
            body: fd
        })
        .then(response => response.json())
        .then(response => {
            if (response.error) throw response;
            this.handleToastNotify(response.message, "Notification", "success");
        })
        .catch(error => {
            this.handleToastNotify("L'opération à échouée!\n" + error.message, "Erreur !", "error");
        })
        .finally(() => {
            this.setState({
                isProcessing: false
            });
        });
    }

    handleChange(e) {
        const person = this.state.person;
        person[e.target.name] = e.target.value;
        this.setState({
            person: person
        });
    }

    handleServiceChange(e) {
        const service = e==null ? this.state.person.service : e.target.value;
        let services = [];
        if (service == "voiture") {
            services = [
                "Lavage de surface",
                "Lavage de précision",
                "Nettoyage complet"
            ];
        }
        else if (service == "maison") {
            services = [
                "Nettoyage intérieur",
                "Nettoyage extérieur",
                "Nettoyage complet",
                "Déboucher l'évier et toilette"
            ];
        }
        this.setState({services: services});
    }

    handleToastNotify(message, title="Notification", type="info") {
        this.setState({
            toast: {
                show: true,
                title: title,
                message: message,
                type: type
            }
        });
    }

    handleCloseToast() {
        const toast = this.state.toast;
        toast.show = false;
        this.setState(toast);
    }

    render() {
        return (
            <>
                <CHeader />
                <main className="flex-auto">
                    <section className="container-fluid py-3">
                        <div className="row">
                            <div className="col-12">
                                <h2 className="border-bottom pb-3">Nous soumettre une demande de rendez-vous</h2>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-5 my-3">
                                <p>
                                    Vous souhaitez inviter notre équipe d'agents d'entretien pour vous offrir nos services de nettoyage ?
                                    <br />
                                    Vous êtes au bon endroit !
                                </p>
                                <p>
                                    Remplissez le formulaire suivant pour nous renseigner 
                                    sur votre besoin et nous allons vous contacter pour confirmer votre rendez-vous ou 
                                    vous proposer une date convenable de commun accord.
                                </p>
                            </div>
                            <div className="col-md-7">
                                <form action="" className="row mb-3" onSubmit={(e) => this.handleFormOffer(e)}>
                                    <div className="form-group col-6 mt-3">
                                        <label htmlFor="personFirstname">Prénom <sup>*</sup> : </label>
                                        <input type="text" className="form-control rounded-0" name="firstName" id="personFirstname" placeholder="Votre Prénom" value={this.state.person.firstName} onInput={(e) => this.handleChange(e)} required />
                                    </div>
                                    <div className="form-group col-6 mt-3">
                                        <label htmlFor="personName">Nom <sup>*</sup> : </label>
                                        <input type="text" className="form-control rounded-0" name="name" id="personName" placeholder="Votre Nom" value={this.state.person.name} onInput={(e) => this.handleChange(e)} required />
                                    </div>
                                    <div className="form-group col-6 mt-3">
                                        <label htmlFor="company">Entreprise : </label>
                                        <input type="text" className="form-control rounded-0" name="company" id="company" placeholder="Nom de votre entreprise (optionel)" value={this.state.person.company} onInput={(e) => this.handleChange(e)} />
                                    </div>
                                    <div className="form-group col-6 mt-3">
                                        <label htmlFor="personCity">Ville <sup>*</sup> : </label>
                                        <input type="text" className="form-control rounded-0" name="city" id="personCity" placeholder="Ville" value={this.state.person.city} onInput={(e) => this.handleChange(e)} required />
                                    </div>
                                    <div className="form-group col-6 mt-3">
                                        <label htmlFor="personAddress">Adresse <sup>*</sup> : </label>
                                        <input type="text" className="form-control rounded-0" name="address" id="personAddress" placeholder="Adresse" value={this.state.person.address} onInput={(e) => this.handleChange(e)} required />
                                    </div>
                                    <div className="form-group col-6 mt-3">
                                        <label htmlFor="personPostalCode">Code postal <sup>*</sup> : </label>
                                        <input type="text" className="form-control rounded-0" name="postalCode" id="personPostalCode" placeholder="Code postal" value={this.state.person.postalCode} onInput={(e) => this.handleChange(e)} required />
                                    </div>
                                    <div className="form-group col-6 mt-3">
                                        <label htmlFor="personPhone">Téléphone <sup>*</sup> : </label>
                                        <input type="number" className="form-control rounded-0" name="phone" id="personPhone" placeholder="Numéro de téléphone" value={this.state.person.phone} onInput={(e) => this.handleChange(e)} required />
                                    </div>
                                    <div className="form-group col-6 mt-3">
                                        <label htmlFor="personEmail">Email <sup>*</sup> : </label>
                                        <input type="email" className="form-control rounded-0" name="email" id="personEmail" placeholder="Email" value={this.state.person.email} onInput={(e) => this.handleChange(e)} required />
                                    </div>
                                    <div className="form-group col-6 mt-3">
                                        <label htmlFor="service">Service sollicité <sup>*</sup> : </label>
                                        <select className="form-control rounded-0" name="service" id="service" value={this.state.person.service} onInput={(e) => { this.handleChange(e); this.handleServiceChange(e); }} required>
                                            <option value="">Choisissez le service que vous sollicité</option>
                                            <option value="maison">Nettoyage de maison</option>
                                            <option value="voiture">Lavage de voiture</option>
                                        </select>
                                    </div>
                                    <div className="form-group col-6 mt-3">
                                        <label htmlFor="type">Type de service <sup>*</sup> : </label>
                                        <select className="form-control rounded-0" name="type" id="type" value={this.state.person.type} onInput={(e) => this.handleChange(e)} required>
                                            <option value="">Choisissez le type de service que vous souhaitez</option>
                                            {this.state.services.map((service, index) => <option value={service} key={"service-" + index}>{service}</option>)}
                                        </select>
                                    </div>
                                    {this.state.person.service=="voiture" &&
                                    <div className="form-group col-6 mt-3">
                                        <label htmlFor="carModel">Model de voiture <sup>*</sup> : </label>
                                        <select className="form-control rounded-0" name="carModel" id="carModel" value={this.state.person.carModel} onInput={(e) => this.handleChange(e) } required>
                                            <option value="">Choisissez le model de voiture</option>
                                            <option>Berline : {this.state.person.type=="Nettoyage complet" ? 70 : (this.state.person.type=="Lavage de précision" ? 35 : 20)}$ </option>
                                            <option>Décapotable : {this.state.person.type=="Nettoyage complet" ? 75 : (this.state.person.type=="Lavage de précision" ? 40 : 25)}$ </option>
                                            <option>VUS : {this.state.person.type=="Nettoyage complet" ? 75 : (this.state.person.type=="Lavage de précision" ? 40 : 25)}$ </option>
                                            <option>Familiale : {this.state.person.type=="Nettoyage complet" ? 80 : (this.state.person.type=="Lavage de précision" ? 45 : 25)}$ </option>
                                            <option>Camionette : {this.state.person.type=="Nettoyage complet" ? 95 : (this.state.person.type=="Lavage de précision" ? 50 : 35)}$ </option>
                                            <option>Minifourgonette : {this.state.person.type=="Nettoyage complet" ? 95 : (this.state.person.type=="Lavage de précision" ? 50 : 35)}$ </option>
                                        </select>
                                    </div>}
                                    {this.state.person.service=="maison" && (this.state.person.type=="Nettoyage intérieur" || this.state.person.type=="Nettoyage complet") &&
                                    <div className="form-group col-6 mt-3">
                                        <label htmlFor="numberOfRoom">Nombre de pièces : </label>
                                        <input type="text" className="form-control rounded-0" name="numberOfRoom" id="numberOfRoom" placeholder="Nombre de pièces de l'intérieur" value={this.state.person.numberOfRoom} onInput={(e) => this.handleChange(e) } />
                                    </div>}
                                    {this.state.person.service=="maison" && (this.state.person.type=="Nettoyage extérieur" || this.state.person.type=="Nettoyage complet") &&
                                    <div className="form-group col-6 mt-3">
                                        <label htmlFor="area">Superfici de l'extérieur : </label>
                                        <input className="form-control rounded-0" name="area" id="area" placeholder="Superficie de l'extérieur" value={this.state.person.area} onInput={(e) => this.handleChange(e) } />
                                    </div>}
                                    <div className="form-group col-6 mt-3">
                                        <label htmlFor="personDate">Date sollicitée <sup>*</sup> : </label>
                                        <input type="date" className="form-control rounded-0" name="date" id="personDate" placeholder="Date sollicitée" value={this.state.person.date} onInput={(e) => this.handleChange(e)} />
                                    </div>
                                    <div className="form-group col-12 mt-3">
                                        <label htmlFor="message">Message <sup>*</sup> : </label>
                                        <textarea type="text" className="form-control rounded-0" name="message" id="message" placeholder="Entrez une bonne description de votre besoin ou de votre requête." rows="8" onInput={(e) => this.handleChange(e)} required></textarea>
                                    </div>
                                    <div className="form-group col-12 mt-3">
                                        <label htmlFor="file">Joindre un fichier (si nécessaire) : </label>
                                        <input type="file" className="form-control rounded-0" name="file[]" id="file" />
                                    </div>
                                    <div className="form-group col-12 mt-3 custom-field">
                                        <input type="checkbox" className="custom-checkbox d-inline" name="concent" id="concent" required />
                                        <label htmlFor="concent">
                                            <sup>*</sup> 
                                            J'accepte que mes informations soient utilisées par GLImm pour me contacter et me localiser dans le but de m'offrir le service que j'ai exprimé en remplissant ce formulaire.
                                        </label>
                                    </div>
                                    <div className="form-group mt-3">
                                        <button className="btn btn-success rounded-0" type="submit" disabled={this.state.isProcessing}>
                                            {this.state.isProcessing && <i className="fa-solid fa-spinner me-2"></i>}
                                            Soumettre la demande 
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </section>
                    { this.state.toast.show &&
                    <div className="toast-container position-fixed bottom-0 end-0 p-3">
                        <div className={"toast show alert alert-" + this.state.toast.type} role="alert" aria-live="assertive" aria-atomic="true">
                            <div className="toast-header">
                                <strong className="me-auto">{ this.state.toast.title }</strong>
                                <small>...</small>
                                <button type="button" className="btn-close" onClick={() => this.handleCloseToast()}></button>
                            </div>
                            <div className="toast-body">
                                { this.state.toast.message }
                            </div>
                        </div>
                    </div>
                    }
                </main>
                <CFooter />
            </>
        );
    }
}