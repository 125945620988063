import React from "react";

import CHeader from "../components/CHeader";
import CFooter from "../components/CFooter";
import { Link } from "react-router-dom";

export default class PAccueil extends React.Component {

    render() {
        return (
            <>
                <CHeader />
                <main className="flex-auto">
                    <section id="services" className="container-fluid container-lg text-center py-5">
                        <div className="row">
                            <div className="col-md-4 d-flex flex-column">
                                <div className="card rounded-0 flex-fill mt-3">
                                    <img className="card-img-top rounded-0"  src="/images/femme-afro-tenant-seau-articles-nettoyage.jpg" alt="glimm entretien - Femme tenant seau et article de nettoyage" />
                                    <div className="card-body d-flex flex-column justify-content-between">
                                        <h1 className="card-title">Service d'entretien mobile</h1>
                                        <h2 className="card-text my-3">
                                            Maison, Voiture, Entrepôt, Bureau...
                                            <br />
                                            Toujours propre !
                                            <br />
                                            Prenez vite rendez-vous ou appelez le <a href="tel:+18193624502" className="text-nowrap">+1 (819) 362-4502</a>.
                                        </h2>
                                        <a href="/abonnement" className="card-link btn btn-lg btn-outline-success rounded-0">Prendre rendez-vous !</a>
                                    </div>
                                    <div className="image-copyright">
                                        Image by mego-studio on Freepik
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 d-flex flex-column">
                                <div className="card rounded-0 flex-fill mt-3">
                                    <img className="card-img-top rounded-0" src="/images/belle-voiture-au-service-lavage.jpg" alt="glimm entretien - Belle voiture au service lavage" />
                                    <div className="card-body d-flex flex-column justify-content-between">
                                        <h2 className="card-title">Protégez votre auto contre la rouille</h2>
                                        <p className="card-text my-3">
                                            En lavant votre voiture régulièrement, elle est protégé contre la rouille pendant l'hiver.
                                            <br />
                                            Choisissez l'un de nos trois forfaits vraiment pas chers.
                                        </p>
                                        <a href="/abonnement?service=voiture" className="card-link btn btn-lg btn-outline-success rounded-0">Abonnez-vous à partir de 20$ !</a>
                                    </div>
                                    <div className="image-copyright">
                                        Image de Freepik
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 d-flex flex-column">
                                <div className="card rounded-0 flex-fill mt-3">
                                    <img className="card-img-top rounded-0"  src="/images/femme-menage-super-amicale.jpg" alt="glimm entretien - Femme tenant seau et article de nettoyage" />
                                    <div className="card-body d-flex flex-column justify-content-between">
                                        <h1 className="card-title">Des professionnels du nettoyage et plus</h1>
                                        <h2 className="card-text my-3">
                                            Abonnez vous au service de nettoyage à petit prix de la région 
                                            et vous trouverez non seulement des professionnels, mais aussi des amis.
                                        </h2>
                                        <a href="/abonnement" className="card-link btn btn-lg btn-outline-success rounded-0">Faire une soumission</a>
                                    </div>
                                    <div className="image-copyright">
                                        Image de wayhomestudio sur Freepik
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section id="banner" className="container-fluid bg-info text-center py-5">
                        <div className="row">
                            <div className="col-md-12">
                                <h2>Simplifiez-vous la vie !</h2>
                                <div className="mt-3">
                                    <p>
                                        Contactez-nous aujourd'hui pour un service de ménage rapide et de qualité.
                                        <br />
                                        Nous vous garantissons la satisfaction à petit prix.
                                    </p>
                                    <Link to="/abonnement" className="btn btn-outline-dark text-uppercase rounded-0 mt-2">Déposer une offre maintenant</Link>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section id="sub-services" className="container-fluid container-lg py-5">
                        <div className="row">
                            <div className="col-12">
                                <h2>Lavage de voiture</h2>
                            </div>
                        </div>
                        <div className="row">
                            <a className="col-sm-6 mt-3 d-flex" href="/abonnement?service=voiture&type=Lavage de surface">
                                <div className="card rounded-0 flex-fill d-flex flex-row">
                                    <img src="/images/belle-voiture-au-service-lavage.jpg" alt="glimm entretien - lavage de voiture" className="card-img-top rounded-0 w-25" />
                                    <div className="card-body">
                                        <h3 className="card-title">Lavage de surface</h3>
                                        <p className="card-text">
                                            Faites garder l'extérieur de votre voiture propre et déblayer le dessous pour éliminer le sel et la saleté incrustés. 
                                            Et votre voiture vous dira merci en fin d'hiver !
                                        </p>
                                    </div>
                                    <div className="card-price d-flex flex-column justify-content-center px-3">
                                        <strong>20$</strong>
                                    </div>
                                    <div className="card-button bg-success text-light d-flex flex-column justify-content-center px-4">
                                        <i className="fa-solid fa-circle-check fa-2x"></i>
                                    </div>
                                    <div className="image-copyright">
                                        Image de Freepik
                                    </div>
                                </div>
                            </a>
                            <a className="col-sm-6 mt-3 d-flex" href="/abonnement?service=voiture&type=Lavage de précision">
                                <div className="card rounded-0 flex-fill d-flex flex-row">
                                    <img src="/images/gros-plan-lavage-voiture.jpg" alt="glimm entretien - lavage de voiture" className="card-img-top rounded-0 w-25" />
                                    <div className="card-body">
                                        <h3 className="card-title">Lavage de précision</h3>
                                        <p className="card-text">
                                            Faites laver votre voiture et bien éponger toutes les surfaces et les jointures. 
                                            Et votre voiture brillera comme neuve !
                                        </p>
                                    </div>
                                    <div className="card-price d-flex flex-column justify-content-center px-3">
                                        <strong>35$</strong>
                                    </div>
                                    <div className="card-button bg-success text-light d-flex flex-column justify-content-center px-4">
                                        <i className="fa-solid fa-circle-check fa-2x"></i>
                                    </div>
                                    <div className="image-copyright">
                                        Image de Freepik
                                    </div>
                                </div>
                            </a>
                            <a className="col-sm-6 mt-3 d-flex" href="/abonnement?service=voiture&type=Nettoyage complet">
                                <div className="card rounded-0 flex-fill d-flex flex-row">
                                    <img src="/images/gros-plan-processus-entretien-voiture.jpg" alt="glimm entretien - entretien de voiture" className="card-img-top rounded-0 w-25" />
                                    <div className="card-body">
                                        <h3 className="card-title">Nettoyage complet</h3>
                                        <p className="card-text">
                                            Faites laver votre voiture et éponger toutes les surfaces et les jointures. 
                                            Ainsi que l'intérieur et tous les recoins !
                                        </p>
                                    </div>
                                    <div className="card-price d-flex flex-column justify-content-center px-3">
                                        <strong>70$</strong>
                                    </div>
                                    <div className="card-button bg-success text-light d-flex flex-column justify-content-center px-4">
                                        <i className="fa-solid fa-circle-check fa-2x"></i>
                                    </div>
                                    <div className="image-copyright">
                                        Image de Freepik
                                    </div>
                                </div>
                            </a>
                            <div className="col-sm-6 mt-3 d-flex">
                                <div className="card rounded-0 flex-fill d-flex flex-row">
                                    <img src="/images/assortiment-elements-du-vendredi-noir.jpg" alt="glimm entretien - entretien de voiture" className="card-img-top rounded-0 w-25" />
                                    <div className="card-body">
                                        <h3 className="card-title">Cadeau d'abonnement</h3>
                                        <p className="card-text">
                                            Abonnez-vous au nettoyage hebdomadaire de votre voiture et obtenez une réduction 
                                            de 20% sur votre dernière facture du mois !
                                        </p>
                                    </div>
                                    <div className="image-copyright">
                                        Image de Freepik
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 mt-4">
                                <h2>Nettoyage de maison, bureau, magasin ou entrepôt</h2>
                            </div>
                        </div>
                        <div className="row">
                            <a className="col-sm-6 mt-3 d-flex" href="/abonnement?service=maison&type=Nettoyage intérieur">
                                <div className="card rounded-0 flex-fill d-flex flex-row">
                                    <img src="/images/table-nettoyage-homme-vue-laterale.jpg" alt="glimm entretien - lavage de voiture" className="card-img-top rounded-0 w-25" />
                                    <div className="card-body">
                                        <h3 className="card-title">Nettoyage intérieur</h3>
                                        <p className="card-text">
                                            Faites nettoyer l'intérieur de votre maison, bureau, magasin ou entrepôt. 
                                            Plancher, meubles, cuisine, toilettes, vaisselle, lessive, repassage, etc.
                                        </p>
                                    </div>
                                    <div className="card-price d-flex flex-column justify-content-center px-3">
                                        <div className="text-center">
                                            <small>
                                                <small>A partir de</small>
                                            </small>
                                            &nbsp;
                                            <strong>50$</strong>
                                        </div>
                                    </div>
                                    <div className="card-button bg-success text-light d-flex flex-column justify-content-center px-4">
                                        <i className="fa-solid fa-circle-check fa-2x"></i>
                                    </div>
                                    <div className="image-copyright">
                                        Image de Freepik
                                    </div>
                                </div>
                            </a>
                            <a className="col-sm-6 mt-3 d-flex" href="/abonnement?service=maison&type=Nettoyage extérieur">
                                <div className="card rounded-0 flex-fill d-flex flex-row">
                                    <img src="/images/volontariat-activisme-fille-africaine-consciente-environnement-nettoie-parc-elle-met-ordures-dans-sac.jpg" alt="glimm entretien - nettoyage extérieur" className="card-img-top rounded-0 w-25" />
                                    <div className="card-body">
                                        <h3 className="card-title">Nettoyage extérieur</h3>
                                        <p className="card-text">
                                            Faites nettoyer l'extérieur de votre maison, bureau, magasin ou entrepôt. 
                                            Murs, cours, pelouse et divers espaces de l'extérieur.
                                        </p>
                                    </div>
                                    <div className="card-price d-flex flex-column justify-content-center px-3">
                                        <div className="text-center">
                                            <small>
                                                <small>A partir de</small>
                                            </small>
                                            &nbsp;
                                            <strong>50$</strong>
                                        </div>
                                    </div>
                                    <div className="card-button bg-success text-light d-flex flex-column justify-content-center px-4">
                                        <i className="fa-solid fa-circle-check fa-2x"></i>
                                    </div>
                                    <div className="image-copyright">
                                        Image de prostooleh sur Freepik
                                    </div>
                                </div>
                            </a>
                            <a className="col-sm-6 mt-3 d-flex" href="/abonnement?service=maison&type=Nettoyage complet">
                                <div className="card rounded-0 flex-fill d-flex flex-row">
                                    <img src="/images/heureux-tenant-seau-outils-nettoyage-eponge-jeune-homme-nettoyant-afro-americain-uniforme-gants-isoles-fond-vert.jpg" alt="glimm entretien - entretien de voiture" className="card-img-top rounded-0 w-25" />
                                    <div className="card-body">
                                        <h3 className="card-title">Nettoyage complet</h3>
                                        <p className="card-text">
                                            Faites nettoyer tous les recoins de votre maison de l'intérieur à l'extérieur.
                                        </p>
                                    </div>
                                    <div className="card-price d-flex flex-column justify-content-center px-3">
                                        <div className="text-center">
                                            <small>
                                                <small>A partir de</small>
                                            </small>
                                            &nbsp;
                                            <strong>80$</strong>
                                        </div>
                                    </div>
                                    <div className="card-button bg-success text-light d-flex flex-column justify-content-center px-4">
                                        <i className="fa-solid fa-circle-check fa-2x"></i>
                                    </div>
                                    <div className="image-copyright">
                                        Image de Freepik
                                    </div>
                                </div>
                            </a>
                            <a className="col-sm-6 mt-3 d-flex" href="/abonnement?service=maison&type=Déboucher l'évier et toilette">
                                <div className="card rounded-0 flex-fill d-flex flex-row">
                                    <img src="/images/housekeeper-cleaning-hotel-room.jpg" alt="glimm entretien - entretien de voiture" className="card-img-top rounded-0 w-25" />
                                    <div className="card-body">
                                        <h3 className="card-title">Déboucher l'évier et toilette</h3>
                                        <p className="card-text">
                                            Faites déboucher votre évier ou vos toilettes bouchées.
                                        </p>
                                    </div>
                                    <div className="card-price d-flex flex-column justify-content-center px-3">
                                        <div className="text-center">
                                            <small>
                                                <small>A partir de</small>
                                            </small>
                                            &nbsp;
                                            <strong>20$</strong>
                                        </div>
                                    </div>
                                    <div className="card-button bg-success text-light d-flex flex-column justify-content-center px-4">
                                        <i className="fa-solid fa-circle-check fa-2x"></i>
                                    </div>
                                    <div className="image-copyright">
                                        Image by rawpixel.com on Freepik
                                    </div>
                                </div>
                            </a>
                            <div className="col-sm-6 mt-3 d-flex">
                                <div className="card rounded-0 flex-fill d-flex flex-row">
                                    <img src="/images/bouquet-fleurs-blanches-dans-vase-cadeau-emballe.jpg" alt="glimm entretien - cadeau d'entretien de maison" className="card-img-top rounded-0 w-25" />
                                    <div className="card-body">
                                        <h3 className="card-title">Cadeau d'abonnement</h3>
                                        <p className="card-text">
                                            Abonnez-vous au nettoyage hebdomadaire de votre maison, bureau, magasin ou entrepôt et obtenez une réduction 
                                            de 40% sur votre dernière facture du mois !
                                        </p>
                                    </div>
                                    <div className="image-copyright">
                                        Image de Freepik
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </main>
                <CFooter />
            </>
        );
    }
}