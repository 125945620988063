import React from "react";

export default class CFooter extends React.Component {

    render() {
        return (
            <footer className="container-fluid bg-dark text-light text-center py-4">
                <span>
                    &copy; GLImm 2023. Tous droits réservés.
                    <br />
                    <small>Développé et hébergé par <a href="https://legrandsoft.com" target="_blank">Groupe legrandsoft</a>, <a href="mailto:contact@legrandsoft.com">contact@legrandsoft.com</a>.</small>
                </span>
            </footer>
        );
    }
}